import { createRouter, createWebHistory } from 'vue-router'
import CaseList from '@/components/CaseList.vue'
import PatientInfoPage from '@/components/PatientInfoPage.vue'
import WoundInfoPage from '@/components/WoundInfoPage.vue'
// 導入其他頁面組件

const routes = [
    {
        path: '/',
        name: 'Home',
        component: CaseList // 將你的首頁組件放在這裡
    },
    {
        path: '/case-list',
        name: 'CaseList',
        component: CaseList
    },
    {
        path: '/patient-info/',
        name: 'PatientInfo',
        component: PatientInfoPage
    },
    {
        path: '/wound-info/',
        name: 'WoundInfo',
        component: WoundInfoPage
    }
    // 定義其他路徑和組件
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;