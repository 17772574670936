<template>
    <div class="case-list-container">
        <div class="title">
            <h1>病患列表</h1>
        </div>
        <div class="toolbar">
            <button @click="addPatient">新增病患</button>
        </div>
        <table class="case-list-table">
            <thead>
                <tr>
                    <th>姓名</th>
                    <th>病歷號碼</th>
                    <th>出生年月日</th>
                    <th>年齡</th>
                    <th>性別</th>
                    <th>主治醫師</th>
                    <th>最後一次追蹤時間</th>
                    <th>下一次追蹤時間</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(caseItem, index) in caseList" :key="index">
                    <td @click="gotoInfo(caseItem)">{{ caseItem.name }}</td>
                    <td>{{ caseItem.healthNumber }}</td>
                    <td>{{ caseItem.birthDate }}</td>
                    <td>{{ caseItem.age }}</td>
                    <td>{{ caseItem.gender }}</td>
                    <td>{{ caseItem.doctor }}</td>
                    <td>{{ caseItem.lastFollowUpDate }}</td>
                    <td>{{ caseItem.nextFollowUpDate }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
  
<script>
export default {
    name: 'CaseList',
    data() {
        return {
            caseList: [
            ],
            selectedPatient: null
        }
    },
    methods: {
        gotoInfo(patient) {
            this.selectedPatient = patient;
        },
        addPatient(patient) {
            this.$store.dispatch('addPatient', patient);
        }
    },
    created() {
        this.caseList = this.$store.state.caseList;
    }
};
</script>
  
<style scoped>
.case-list-container {
    margin: 20px;
}

.toolbar {
    margin-bottom: 20px;
    text-align: right;
}

.toolbar button {
    padding: 10px 20px;
    background-color: #333;
    color: white;
    border: none;
    cursor: pointer;
}

.toolbar button:hover {
    background-color: #555;
}

.case-list-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    background-color: white;
    text-align: left;
    line-height: 1.5;
}

.case-list-table th,
.case-list-table td {
    padding: 8px;
    border: 1px solid #ddd;
}

.case-list-table th {
    background-color: #f2f2f2;
    color: black;
}
</style>