import { createStore } from 'vuex';

export default createStore({
    state() {
        return {
            // 初始狀態
            caseList: [
                {
                    "id": 1,
                    "name": "個案A",
                    "healthNumber": "1131643649",
                    "birthDate": "1933/07/11",
                    "age": "25Y",
                    "gender": "女",
                    "doctor": "林智明",
                    "address": "XX市XX區XX路1號",
                    "nurse": "劉美美",
                    "diagnosis": "哮喘",
                    "surgeryMethod": "吸入性類固醇",
                    "surgeryDate": "2023/12/02",
                    "lastFollowUpDate": "2023/11/21",
                    "nextFollowUpDate": "2024/01/07"
                },
                {
                    "id": 2,
                    "name": "個案B",
                    "healthNumber": "5213417282",
                    "birthDate": "1988/10/20",
                    "age": "65Y",
                    "gender": "女",
                    "doctor": "王小虎",
                    "address": "XX市XX區XX路2號",
                    "nurse": "劉美美",
                    "diagnosis": "哮喘",
                    "surgeryMethod": "心臟搭橋手術",
                    "surgeryDate": "2023/05/29",
                    "lastFollowUpDate": "2023/11/17",
                    "nextFollowUpDate": "2024/03/23"
                },
                {
                    "id": 3,
                    "name": "個案C",
                    "healthNumber": "2582633251",
                    "birthDate": "1979/08/27",
                    "age": "38Y",
                    "gender": "女",
                    "doctor": "李阿花",
                    "address": "XX市XX區XX路3號",
                    "nurse": "林小蘭",
                    "diagnosis": "糖尿病",
                    "surgeryMethod": "吸入性類固醇",
                    "surgeryDate": "2023/11/28",
                    "lastFollowUpDate": "2023/11/02",
                    "nextFollowUpDate": "2024/03/11"
                },
                {
                    "id": 4,
                    "name": "個案D",
                    "healthNumber": "7684011917",
                    "birthDate": "1990/12/22",
                    "age": "75Y",
                    "gender": "女",
                    "doctor": "王小虎",
                    "address": "XX市XX區XX路4號",
                    "nurse": "陳小花",
                    "diagnosis": "哮喘",
                    "surgeryMethod": "心臟搭橋手術",
                    "surgeryDate": "2023/07/15",
                    "lastFollowUpDate": "2023/12/19",
                    "nextFollowUpDate": "2024/02/06"
                },
                {
                    "id": 5,
                    "name": "個案E",
                    "healthNumber": "2267398537",
                    "birthDate": "1992/03/13",
                    "age": "61Y",
                    "gender": "女",
                    "doctor": "李阿花",
                    "address": "XX市XX區XX路5號",
                    "nurse": "王麗麗",
                    "diagnosis": "哮喘",
                    "surgeryMethod": "心臟搭橋手術",
                    "surgeryDate": "2023/06/18",
                    "lastFollowUpDate": "2023/11/26",
                    "nextFollowUpDate": "2024/02/29"
                },
                {
                    "id": 6,
                    "name": "個案F",
                    "healthNumber": "1300774579",
                    "birthDate": "1960/12/13",
                    "age": "30Y",
                    "gender": "男",
                    "doctor": "王小虎",
                    "address": "XX市XX區XX路6號",
                    "nurse": "劉美美",
                    "diagnosis": "哮喘",
                    "surgeryMethod": "骨質增密手術",
                    "surgeryDate": "2023/07/12",
                    "lastFollowUpDate": "2023/11/24",
                    "nextFollowUpDate": "2024/03/30"
                },
                {
                    "id": 7,
                    "name": "個案G",
                    "healthNumber": "1271990520",
                    "birthDate": "1944/01/06",
                    "age": "87Y",
                    "gender": "男",
                    "doctor": "張大明",
                    "address": "XX市XX區XX路7號",
                    "nurse": "劉美美",
                    "diagnosis": "骨質疏鬆",
                    "surgeryMethod": "胰島素注射",
                    "surgeryDate": "2023/08/08",
                    "lastFollowUpDate": "2023/12/03",
                    "nextFollowUpDate": "2024/03/08"
                },
                {
                    "id": 8,
                    "name": "個案H",
                    "healthNumber": "5691972272",
                    "birthDate": "1961/05/07",
                    "age": "73Y",
                    "gender": "男",
                    "doctor": "王小虎",
                    "address": "XX市XX區XX路8號",
                    "nurse": "劉美美",
                    "diagnosis": "骨質疏鬆",
                    "surgeryMethod": "骨質增密手術",
                    "surgeryDate": "2023/10/26",
                    "lastFollowUpDate": "2023/11/20",
                    "nextFollowUpDate": "2024/02/14"
                },
                {
                    "id": 9,
                    "name": "個案I",
                    "healthNumber": "8323489056",
                    "birthDate": "1999/08/02",
                    "age": "31Y",
                    "gender": "女",
                    "doctor": "林智明",
                    "address": "XX市XX區XX路9號",
                    "nurse": "王麗麗",
                    "diagnosis": "糖尿病",
                    "surgeryMethod": "骨質增密手術",
                    "surgeryDate": "2023/10/15",
                    "lastFollowUpDate": "2023/12/04",
                    "nextFollowUpDate": "2024/02/09"
                },
                {
                    "id": 10,
                    "name": "個案J",
                    "healthNumber": "9945946083",
                    "birthDate": "1944/01/13",
                    "age": "67Y",
                    "gender": "男",
                    "doctor": "李阿花",
                    "address": "XX市XX區XX路10號",
                    "nurse": "林小蘭",
                    "diagnosis": "糖尿病",
                    "surgeryMethod": "骨質增密手術",
                    "surgeryDate": "2023/05/01",
                    "lastFollowUpDate": "2023/12/22",
                    "nextFollowUpDate": "2024/02/03"
                }
            ]
            ,
            selectedPatientId: null
        };
    },
    getters: {
        getPatientById: (state) => (id) => {
            return state.caseList.find(patient => patient.id === id);
        },
        // 可以添加getters以獲取store中的狀態
    },
    mutations: {
        // 使用mutations來更改狀態
        ADD_PATIENT(state, patient) {
            state.caseList.push(patient);
        }
    },
    actions: {
        // 使用actions來執行異步操作後再變更狀態
        addPatient({ commit }, patient) {
            commit('ADD_PATIENT', patient);
        }
    }
});