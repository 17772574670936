<template>
    <div class="info-section info-group">
        <h1>個人資訊</h1>
        <div class="info-container">
            <label>姓名:</label>
            <p>{{ exampleDetail.name }}</p>
        </div>
        <div class="info-container">
            <label>病歷號碼:</label>
            <p>{{ exampleDetail.healthNumber }}</p>
        </div>
        <div class="info-container">
            <label>出生年月日:</label>
            <p>{{ exampleDetail.birthDate }}</p>
        </div>
        <div class="info-container">
            <label>年齡:</label>
            <p>{{ exampleDetail.age }}</p>
        </div>
        <div class="info-container">
            <label>性別:</label>
            <p>{{ exampleDetail.gender }}</p>
        </div>
        <div class="info-container">
            <label>主治醫生:</label>
            <p>{{ exampleDetail.doctor }}</p>
        </div>
        <div class="info-container">
            <label>上次追蹤日期:</label>
            <p>{{ exampleDetail.lastFollowUpDate }}</p>
        </div>
        <div class="info-container">
            <label>下次追蹤日期:</label>
            <p>{{ exampleDetail.nextFollowUpDate }}</p>
        </div>
        <div class="info-container">
            <label>地址:</label>
            <p>{{ exampleDetail.address }}</p>
        </div>
        <div class="info-container">
            <label>護士:</label>
            <p>{{ exampleDetail.nurse }}</p>
        </div>
        <div class="info-container">
            <label>診斷:</label>
            <p>{{ exampleDetail.diagnosis }}</p>
        </div>
        <div class="info-container">
            <label>手術方法:</label>
            <p>{{ exampleDetail.surgeryMethod }}</p>
        </div>
        <div class="info-container">
            <label>手術日期:</label>
            <p>{{ exampleDetail.surgeryDate }}</p>
        </div>
    </div>

    <!-- 圖片容器 -->
    <div class="image-container">
        <div class="image-box">
            <button class="image-button">歷史傷口照片</button>
            <img :src="require('@/components/assets/wound.png')" alt="傷口" class="case-image" />
        </div>
        <div class="image-box">
            <button class="image-button">照護紀錄</button>
            <img :src="require('@/components/assets/medical.png')" alt="藥物" class="case-image" />
        </div>
        <div class="image-box">
            <button class="image-button">醫材使用紀錄</button>
            <img :src="require('@/components/assets/met.png')" alt="醫療圖片" class="case-image" />
        </div>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            exampleDetail: {
                id: 1,
                name: '個案A',
                healthNumber: '00123447876',
                birthDate: '1950/01/02',
                age: '74Y',
                gender: '男',
                doctor: '李阿花',
                lastFollowUpDate: '2023/12/01',
                nextFollowUpDate: '2024/01/02',
                address: '台北市信義區XX路1號',
                nurse: '劉美美',
                diagnosis: '糖尿病',
                surgeryMethod: '胰島素注射',
                surgeryDate: '2023/05/15'
            },
            patientDetails: null
        }
    },
    created() {
        this.patientDetails = this.$store.getters.getPatientById(this.$store.state.selectedPatientId);
    }
};
</script>
  
<style>
.info-section {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* 定義三個等寬的欄位 */
    gap: 20px;
    /* 定義欄位之間的間距 */
    font-family: 'Arial', sans-serif;
    color: #333;
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.info-container {
    margin-bottom: 10px;
}

.info-container label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.info-container p {
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 4px;
}

.image-container {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.image-box {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.image-button {
    margin-bottom: 10px;
    /* 設定按鈕和圖片之間的距離 */
    padding: 10px 20px;
    /* 按鈕內邊距 */
    background-color: #007bff;
    /* 按鈕顏色 */
    color: white;
    /* 文字顏色 */
    border: none;
    /* 去除邊框 */
    border-radius: 5px;
    /* 圓角邊框 */
    cursor: pointer;
    /* 鼠標懸停時顯示手型標誌 */
    outline: none;
    /* 點擊時去除輪廓 */
    font-size: 16px;
    /* 文字大小 */
    transition: background-color 0.3s;
    /* 過渡效果 */
}

.image-button:hover {
    background-color: #0056b3;
    /* 滑鼠懸停時按鈕顏色變深 */
}

.case-image {
    width: 200px;
    height: auto;
    border-radius: 8px;
    object-fit: cover;
}

/* 圖片的 CSS */
.case-image {
    width: 450px;
    /* 根據需求調整圖片寬度 */
    height: auto;
    /* 保持圖片比例 */
    border-radius: 8px;
    /* 圖片邊角圓滑，可選擇 */
}</style>