<template>
    <div class="container">
        <div class="content">
            <!-- 原有內容放這裡 -->
            <div class="info-section info-group">
                <h1>照護紀錄 {{ todayDate }}</h1>
                <div class="info-container">
                    <label>姓名:</label>
                    <p>{{ exampleInjureDetail.name }}</p>
                </div>
                <div class="info-container">
                    <label>傷口類別:</label>
                    <p>{{ exampleInjureDetail.class }}</p>
                </div>
                <div class="info-container">
                    <label>發生日期:</label>
                    <p>{{ exampleInjureDetail.injureDate }}</p>
                </div>
                <div class="info-container">
                    <label>部位:</label>
                    <p>{{ exampleInjureDetail.part }}</p>
                </div>
                <div class="info-container">
                    <label>範圍:</label>
                    <p>{{ exampleInjureDetail.range }}</p>
                </div>
                <div class="info-container">
                    <label>顏色:</label>
                    <p>{{ exampleInjureDetail.colur }}</p>
                </div>
                <div class="info-container">
                    <label>性質:</label>
                    <p>{{ exampleInjureDetail.exudateKind }}</p>
                </div>
                <div class="info-container">
                    <label>滲液量:</label>
                    <p>{{ exampleInjureDetail.exudateQuantity }}</p>
                </div>
                <div class="info-container">
                    <label>文字敘述:</label>
                    <p>{{ exampleInjureDetail.memo }}</p>
                </div>
                <div class="info-container">
                    <label>紀錄時間:</label>
                    <p>{{ exampleInjureDetail.createdDate }}</p>
                </div>
            </div>


            <!-- 圖片容器 -->
            <div class="image-container">
                <div class="image-box">
                    <button class="image-button">上傳照片</button>
                    <img :src="require('@/components/assets/wound2.jpg')" alt="傷口" class="case-image" />
                </div>
            </div>
        </div>

        <!-- 選單區 -->
        <div class="sidebar">
            <h1>紀錄日期</h1>
            <div class="navbar" v-for="item in careDateList" :key="item">
                <div class="info-container">
                    <button class="date-btn">{{ item }}</button>
                    <!-- 添加其他router-link -->
                </div>
            </div>
            <button class="image-button">新增照護紀錄</button>
        </div>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            exampleInjureDetail: {
                patientId: '1',
                name: '個案A',
                class: '術後傷口',
                injureDate: '2022-12-01',
                createdDate: '2022-12-21 12:12:25',
                part: '右臉',
                range: '10 * 5 * 10 cm',
                colur: '紅色',
                exudateKind: '膿液',
                exudateQuantity: '少量 or 10cc',
                memo: '持續滲血，一般換藥 or 濕敷，頻率為一天四次',
            },
            injureDetail: null,
            todayDate: new Date().toISOString().slice(0, 10),
            careDateList: [
                '2023-12-21', '2023-12-20', '2023-12-19', '2023-12-18', '2023-12-17'
            ]
        }
    },
    created() {
        this.injureDetail = this.$store.getters.getPatientById(this.$store.state.selectedPatientId);
    }
};
</script>
  
<style>
.info-section {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* 定義三個等寬的欄位 */
    gap: 20px;
    /* 定義欄位之間的間距 */
    font-family: 'Arial', sans-serif;
    color: #333;
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.info-container {
    margin-bottom: 10px;
}

.info-container label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.info-container p {
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 4px;
}

.image-container {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.image-box {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.image-button {
    margin-bottom: 10px;
    /* 設定按鈕和圖片之間的距離 */
    padding: 10px 20px;
    /* 按鈕內邊距 */
    background-color: #007bff;
    /* 按鈕顏色 */
    color: white;
    /* 文字顏色 */
    border: none;
    /* 去除邊框 */
    border-radius: 5px;
    /* 圓角邊框 */
    cursor: pointer;
    /* 鼠標懸停時顯示手型標誌 */
    outline: none;
    /* 點擊時去除輪廓 */
    font-size: 16px;
    /* 文字大小 */
    transition: background-color 0.3s;
    /* 過渡效果 */
}

.image-button:hover {
    background-color: #0056b3;
    /* 滑鼠懸停時按鈕顏色變深 */
}

.case-image {
    width: 200px;
    height: auto;
    border-radius: 8px;
    object-fit: cover;
}

/* 圖片的 CSS */
.case-image {
    width: 450px;
    /* 根據需求調整圖片寬度 */
    height: auto;
    /* 保持圖片比例 */
    border-radius: 8px;
    /* 圖片邊角圓滑，可選擇 */
}

.container {
    display: flex;
    justify-content: space-between;
}

.sidebar {
    width: 200px;
    /* 或您需要的寬度 */
}

.content {
    flex-grow: 1;
}

/* 側邊欄樣式 */
.sidebar {
    width: 200px;
    background-color: #f3f3f3;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}


/* 日期按鈕樣式 */
.date-btn {
    background-color: #ffffff;
    color: #333333;
    border: 2px solid #dddddd;
    padding: 10px 15px;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.date-btn:hover {
    background-color: #4CAF50;
    color: #ffffff;
    border-color: #4CAF50;
}
</style>