<template>
    <div class="navbar">
        <router-link to="/">傷造口個案管理資訊系統</router-link>
        <router-link to="/case-list">病患列表</router-link>
        <router-link to="/patient-info">病患個人資訊</router-link>
        <router-link to="/wound-info">照護紀錄</router-link>
        <router-link to="/patient-info">歷史傷口照片</router-link>
        <router-link to="/patient-info">醫材使用紀錄</router-link>
        <router-link to="/patient-info">訪視紀錄</router-link>
        <!-- 添加其他router-link -->
    </div>
</template>
  
<script>
export default {
    name: 'AppNavbar'
};
</script>
  
<style scoped>
.navbar {
    background-color: #333;
    overflow: hidden;
}

.navbar a {
    float: left;
    display: block;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
}

/* 改變激活的router-link顏色 */
.router-link-exact-active {
    background-color: #555;
}

/* 滑鼠懸停的樣式 */
.navbar a:hover {
    background-color: #ddd;
    color: black;
}
</style>